import styled from 'styled-components'
import CallBg from 'assets/banner.png'
import Grid from '@material-ui/core/Grid'
import { hex2rgba, lightenDarkenColor } from 'prace-common-components'

export const $Background = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 370px;
  background-image: ${({ theme }) => `url(${CallBg}), linear-gradient(90deg, ${lightenDarkenColor(theme.colors.primary, -20)} 0%, ${hex2rgba(theme.colors.primary, 1, true)} 60%, ${lightenDarkenColor(theme.colors.primary, 15)} 100%)`};
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${({ theme }) => theme.colors.primary};
`

//TODO: Remove margin-top 40px when the top banner is removed
export const $OpenCalls = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  height: max-content;
  padding-bottom: 60px;
  max-width: 1200px;
`

export const $PrevCalls = styled(Grid)`
  height: max-content;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  max-width: 1200px;
  min-height: max-content;
`

export const $TabsContainer = styled(Grid)`
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const $PageTitle = styled.h3`
  margin: ${({ theme }) => theme.spacing(5)};
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.white};
`

export const $OpenTitle = styled.h3`
  margin: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(5)}`};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.colors.white};
  font-weight: lighter;
  font-size: 1.5em;
`

export const $CardContainer = styled(Grid)`
  @media (max-width: 768px) {
    justify-content: center;
  }
`
