import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'constants/global'
import { $Link, $ApplyNotice, $Home, $NoticeTitle, $ImgContainer, $NoticeItem, $RowContainer, $TopContainer, $ActionSvgIcon, $ActionRow, $SvgIcon, $RowText } from './styles'
import { Title, NewLoading, DataRow, NewButton, FILTER_TYPE, DataTable, ROLES, Select, ROLE_NAME, Grid, FLOW_STATUS, BrowserLocaleDateFormatter, BrowserLocaleTimeFormatter } from 'prace-common-components'
import { useQuery } from 'util/useQuery'
import apply from 'assets/apply.svg'
import { useGetDashboardMutation } from 'store/api/applications'
import { DashboardFlowAssignment } from 'store/api/applications/types'

type AllFilterType = { orderBy: {[key: string]: 'ASC' | 'DESC'}}

export enum DASHBOARD_FIELDS {
  ID = 'uid',
  STEP = 'step',
  PI_FIRST_NAME = 'firstName',
  PI_LAST_NAME = 'lastName',
  PI_NAME = 'name',
  PROJECT_TITLE = 'projectTitle',
  PARTITION = 'partition',
  DEADLINE = 'deadline',
}

/* Actions descriptions */
const DASHBOARD_ACTIONS = {
  [FLOW_STATUS.DRAFT]: 'Submit Application',
  [FLOW_STATUS.CHANGES_REQUESTED]: 'Submit Changes',
  [FLOW_STATUS.ASSIGNED]: 'Accept Assignment',
  [FLOW_STATUS.ACCEPTED]: 'Submit Form',
  [FLOW_STATUS.SUBMITTED]: '-',
  [FLOW_STATUS.REJECTED]: '-',
  [FLOW_STATUS.APPROVED]: '-',
  [FLOW_STATUS.REVOKED]: '-',
  [FLOW_STATUS.RESUBMITTED]: '-',
  [FLOW_STATUS.CONFLICTED]: '-',
  [FLOW_STATUS.REFUSED]: '-',
  [FLOW_STATUS.TRIGGERED]: '-',
}

const DASHBOARD_ICONS = {
  [FLOW_STATUS.DRAFT]: 'pendingSubmission',
  [FLOW_STATUS.CHANGES_REQUESTED]: 'requestChanges',
  [FLOW_STATUS.ASSIGNED]: 'pendingAcceptance',
  [FLOW_STATUS.ACCEPTED]: 'pendingSubmission',
  [FLOW_STATUS.SUBMITTED]: '',
  [FLOW_STATUS.REJECTED]: '',
  [FLOW_STATUS.APPROVED]: '',
  [FLOW_STATUS.REVOKED]: '',
  [FLOW_STATUS.RESUBMITTED]: '',
  [FLOW_STATUS.CONFLICTED]: '',
  [FLOW_STATUS.REFUSED]: '',
  [FLOW_STATUS.TRIGGERED]: '',
}

export const Dashboard: FC<{isMobile?: boolean}> = () => {
  const { query } = useQuery()
  const roleQuery = query.get('role') || ''
  const order = query.get('order') as unknown as DASHBOARD_FIELDS || ''
  const orderDirection: 'ASC' | 'DESC' = query.get('direction') === 'ASC' ? 'ASC' : 'DESC'
  const navigate = useNavigate()
  const [allFilters, setAllFilters] = useState<AllFilterType>({
    orderBy: order ? {[order as DASHBOARD_FIELDS]: orderDirection} : {[DASHBOARD_FIELDS.DEADLINE]: 'ASC'},
  })
  const [role, setRole] = useState<ROLES | undefined>(roleQuery as ROLES)
  const [getDashboard, { data: applications, isLoading, isUninitialized }] = useGetDashboardMutation()
  const applicationsData = applications?.data || []
  const noApps = !applicationsData.length

  const filteredRoleItems = applications?.roles?.map((r) => ({ label: ROLE_NAME[r], value: r })) || []

  useEffect(() => {
    const getApplications = async () => {
      try {
        await getDashboard({ role, limit: 9999, offset: 0 }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    getApplications().catch((err) => console.log(err))
  }, [getDashboard, role])

  const onSortColumn = (columnKey: string, direction?: 'ASC' | 'DESC') => {
    setAllFilters({ orderBy: {[columnKey]: direction as 'ASC' | 'DESC'} })
  }

  const onRole = (role: ROLES) => {
    setRole(role)
  }

  const columns = [
    {
      key: DASHBOARD_FIELDS.ID,
      type: FILTER_TYPE.NONE,
      name: 'Proposal ID',
      width: 200,
      formatter(props: { row: DataRow }) {
        const { uid, applicationId, status } = props.row
        return (
          <$RowContainer data-simplebar>
            <$Link to={Routes.APPLICATION(uid)}>
              {status === FLOW_STATUS.DRAFT ? `DRAFT-${applicationId}`: uid}
            </$Link>
          </$RowContainer>
        )
      },
    },
    { key: DASHBOARD_FIELDS.PROJECT_TITLE, type: FILTER_TYPE.NONE, name: 'Project', noSort: true },
    { key: DASHBOARD_FIELDS.PI_NAME, type: FILTER_TYPE.NONE, name: 'PI Name' },
    { key: DASHBOARD_FIELDS.STEP, type: FILTER_TYPE.NONE, name: 'Step', noSort: true, width: 150 },
    { key: DASHBOARD_FIELDS.PARTITION, type: FILTER_TYPE.NONE, name: 'Partition', width: 150 },
    { key: 'actions', type: FILTER_TYPE.NONE, name: 'Action', noSort: true, width: 220,
      formatter(props: { row: DataRow }) {
        const { actions = '', uid, expired } = props.row
        const action = DASHBOARD_ACTIONS[actions as FLOW_STATUS] || '-'
        return <div data-simplebar>
          <$Link to={Routes.APPLICATION(uid)}>
            <$ActionRow expired={expired}>
              <$ActionSvgIcon expired={expired} size='28' name={DASHBOARD_ICONS[actions as FLOW_STATUS]} />
              {action}
              <$SvgIcon size='20' name='chevron-right' />
            </$ActionRow>
          </$Link>
        </div>
      }},
    { key: DASHBOARD_FIELDS.DEADLINE, type: FILTER_TYPE.NONE, name: 'Deadline', width: 160,
      formatter(props: { row: DataRow }) {
        const { deadline = '', expired } = props.row
        const deadlineInThreeDays = deadline ? 
          (new Date(deadline) < new Date(new Date().setDate(new Date().getDate() + 3))) : false
        return <$RowText highlight={deadlineInThreeDays} expired={expired} data-simplebar>
          {deadline ? `${BrowserLocaleDateFormatter.format(new Date(deadline))} ${BrowserLocaleTimeFormatter.format(new Date(deadline))}` : 'No deadline'}
        </$RowText>
      }},
  ]

  let rows: DashboardFlowAssignment[] = []
  applicationsData.forEach((app) => {
    rows = [
      ...rows,
      ...app.assignments.map((assignment) => ({
        ...assignment,
        partition: assignment.partition || app.partition || '-',
        applicationId: app.id,
        uid: app.uid,
        expired: !!assignment.deadline && (new Date(assignment.deadline) < new Date()),
        actions: assignment.status,
        projectTitle: app.projectTitle || '-',
        name: app.firstName ? `${app.firstName} ${app.lastName}` : '-',
      })),
    ]
  })
  const total = rows.length || 0

  const sortedRows = rows.sort((a, b) => {
    const key = Object.keys(allFilters.orderBy)[0] as keyof DashboardFlowAssignment
    const direction = allFilters.orderBy[key]
    if (key === DASHBOARD_FIELDS.DEADLINE) {
      const dateA = new Date(a[key] as string).getTime()
      const dateB = new Date(b[key] as string).getTime()
      if (direction === 'ASC') {
        return dateA - dateB
      }
      return dateB - dateA
    }
    if (direction === 'ASC') {
      return a[key] > b[key] ? 1 : -1
    }
    return a[key] < b[key] ? 1 : -1
  })

  const noApplications = noApps && !isLoading && !isUninitialized && noApps

  return (
    <>
      <NewLoading loading={isUninitialized || isLoading} />
      <$Home>
        <$TopContainer container alignItems='center' justifyContent='space-between'>
          <Grid item xs>
            <Title alternate fontSize={22}>Dashboard</Title>
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Select
              name='role'
              placeholder='Select a Role'
              items={filteredRoleItems}
              hideItalic
              onChange={(_n, value) => onRole(value as ROLES)}
              value={role}
            />
          </Grid>
        </$TopContainer>        
        {noApplications && !isLoading ?
          <$ApplyNotice>
            <$ImgContainer>
              <img src={apply} alt='apply' />
            </$ImgContainer>
            <$NoticeItem>
              {(role && role !== ROLES.APPLICANT) ? 
                <$NoticeTitle>You have no pending actions for the selected role {ROLE_NAME[role]}.</$NoticeTitle>
                :
                <$NoticeTitle>It looks like you don’t have <br /> any pending action at the moment.</$NoticeTitle>
              }
            </$NoticeItem>
            {(!role || role === ROLES.APPLICANT) && <$NoticeItem>
              <NewButton onClick={() => navigate(Routes.HOME)}>Apply To Calls</NewButton>
            </$NoticeItem>}
          </$ApplyNotice>
          :
          <>
            <DataTable
              initialSort={[{ columnKey: DASHBOARD_FIELDS.DEADLINE, direction: 'DESC' }]} // Initial Sort
              rows={sortedRows}
              columns={columns}
              total={total}
              noFilterType
              localFilter={false}
              onSortColumn={onSortColumn}
              noMultipleSorting
              noFilters
            />
          </>
        }
      </$Home>
    </>
  )
}
