import React, { FC, useEffect, useState } from 'react'
import { Title, DrawerModal, ApplicationDocuments, ActivityDates, NewButton, newTheme as theme, ConfirmAction, FLOW_STATUS, FLOW_STATUS_NAMES, ROLES, ROLE_NAME_INITIALS, BrowserLocaleDateFormatter } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'
import { $ReviewContainer, $ReviewEntry, $AddAssignment, $Divider, $Buttons, $ApplicationActions, $AssignmentContainer, $ActionsContainer, $Partition, $PartitionName } from './styles'
import { ApplicationActionsProps } from './types'

export const ApplicationActions: FC<ApplicationActionsProps> = ({
  call,
  step,
  handleSelect,
  handleDownloadClick,
  proposalId = '',
  documents,
  assignments,
  handleOpenAssign,
  onDeleteApplication,
  setLeadAssignment,
  selectedAssignment,
  canAssign,
  hasLead,
  canDeleteApplication,
  assignmentPartition,
  downloadConsolidated,
  canMakeLead,
  loading,
}) => {
  const [documentsOpen, setDocumentsOpen] = useState<boolean>(false)
  const handleSelectAssignment = (assignmentIdx: number) => {
    handleSelect(assignmentIdx, 'assignment')
  }

  const assignmentLength = assignments.length
  const hideMenu = (assignmentLength == 1) && !canAssign

  const selectedAssignmentExists = selectedAssignment < assignmentLength

  useEffect(() => {
    if (assignmentLength && !selectedAssignmentExists) {
      handleSelect(0, 'assignment')
    }
  }, [assignmentLength, selectedAssignmentExists, handleSelect])

  const currentAssignment = assignmentLength && selectedAssignmentExists ? assignments[selectedAssignment] : { activities: [], deadline: '', submittedAt: '', status: '' }
  const { deadline, submittedAt, status } = currentAssignment
  const changesRequested = !!submittedAt && (status == FLOW_STATUS.CHANGES_REQUESTED)

  const activityReasons = currentAssignment.activities?.filter(
    (act) => (!!act?.reason && act?.status !== FLOW_STATUS.ACCEPTED))

  const showComments = !!activityReasons.length

  /* Do not show any data of other steps if the application is in draft yet */
  if(step > 0 && canDeleteApplication) return null

  return (
    <$ApplicationActions container spacing={2}>
      {assignmentLength && !canDeleteApplication ? // Do not show Assignments list if the application is in draft
        <Grid item xs={12}>
          <Title alternate noMargin>Assignments</Title>
          {assignments.map((assignment, assignmentIdx: number) => {
            const activityReasons = assignment.activities?.filter(
              (act) => (!!act?.reason && act?.status !== FLOW_STATUS.ACCEPTED))
            return <$AssignmentContainer container key={assignmentIdx}>
              <Title
                subTitle
                onClick={() => handleSelectAssignment(assignmentIdx)}
                noMargin
                icon='circle'
                hasBack
                multiline
                bold={selectedAssignment == assignmentIdx}
                iconProps={{color: theme.colors.secondary, size: 10, clickable: false}}
                color={selectedAssignment == assignmentIdx ? theme.colors.secondary : undefined}>
                {hasLead && assignment.isLead && 'Lead - '}
                {assignment.owner?.firstName ?
                  `${assignment.owner?.firstName || ''} ${assignment.owner?.lastName || ''} (${assignment.owner?.role ? ROLE_NAME_INITIALS[assignment.owner.role as ROLES] : 'Assignment'}) \n ${FLOW_STATUS_NAMES[assignment.status]}` // ${canAssign ? ` - ${assignment.status}` : ''}
                  : `#${assignmentIdx + 1} ${assignment.owner?.role ? ROLE_NAME_INITIALS[assignment.owner.role as ROLES] : 'Assignment'} \n ${FLOW_STATUS_NAMES[assignment.status]}`}
              </Title>
              {selectedAssignment == assignmentIdx && canAssign && assignment.status !== FLOW_STATUS.REFUSED &&
               canMakeLead && hasLead && !assignment.isLead &&
                <$ActionsContainer>
                  <Grid container direction='column' spacing={1}>
                    <Grid item xs>
                      <NewButton fullWidth variant='outlined' onClick={() => setLeadAssignment(assignment)}>Make Lead Assignment</NewButton>
                    </Grid>
                  </Grid>
                </$ActionsContainer>}
              {selectedAssignment == assignmentIdx &&
                <Grid container direction='column' spacing={1}>
                  {assignmentPartition && <Grid item xs>
                    <$Partition>Partition: <$PartitionName>{assignmentPartition.name || ''}</$PartitionName></$Partition>
                  </Grid>}
                  {showComments && <Grid item xs>
                    <$Divider />
                    <Title subTitle noMargin>Review Comments</Title>
                    <$ReviewContainer>
                      {activityReasons.map((activity) => <Grid item xs key={activity.id}>
                        <$ReviewEntry>
                          <b>{`${BrowserLocaleDateFormatter.format(new Date(activity.createdAt))} ${FLOW_STATUS_NAMES[activity.status]}: `}</b>{activity?.reason || ''}
                        </$ReviewEntry>
                      </Grid>)}
                    </$ReviewContainer>
                  </Grid>}
                  {(assignment.submittedAt || assignment.deadline) && <Grid item xs>
                    <ActivityDates
                      deadline={
                        (assignment.submittedAt && (assignment.status !== FLOW_STATUS.CHANGES_REQUESTED)) 
                        || !assignment.deadline ? undefined : new Date(assignment.deadline)}
                      submit={assignment.submittedAt ? new Date(assignment.submittedAt) : undefined}
                    />
                  </Grid>}
                </Grid>
              }
            </$AssignmentContainer>
          })}
        </Grid>
        : hideMenu && (submittedAt || deadline || assignmentPartition || showComments) ?
          <Grid container>
            {assignmentPartition && <Grid item xs={12}>
              <$Partition>Partition: <$PartitionName>{assignmentPartition.name || ''}</$PartitionName></$Partition>
            </Grid>}
            {(submittedAt || deadline) && <Grid item xs>
              <ActivityDates
                deadline={(submittedAt && !changesRequested) || !deadline ? undefined : new Date(deadline)}
                submit={submittedAt ? new Date(submittedAt) : undefined}
              />
            </Grid>}
            {showComments && <>
              <$Divider />
              <Title subTitle noMargin>Review Comments</Title>
              <$ReviewContainer>
                {activityReasons.map((activity, idx: number) => <Grid item xs key={activity.id}>
                  <$ReviewEntry>
                    <b>{`#${idx+1}: `}</b>{activity?.reason || ''}
                  </$ReviewEntry>
                </Grid>)}
              </$ReviewContainer>
            </>}
          </Grid>: null
      }
      {canAssign &&
        <$AddAssignment>
          <NewButton creator variant='text' onClick={handleOpenAssign}>
            Add Assignment
          </NewButton>
        </$AddAssignment>
      }
      {!!assignmentLength && <Grid item xs={12}>
        <Title subTitle onClick={() => setDocumentsOpen(true)} color={theme.colors.primary} hasBack iconProps={{color: theme.colors.primary, clickable: false}} noMargin icon='documents'>Documents</Title>
      </Grid>}
      {canDeleteApplication && <$Buttons item xs={12}>
        <ConfirmAction disabled={loading} deletion onConfirm={onDeleteApplication} title='Delete Application' description='All the progress and information associated to this application will be lost.'>
          <NewButton disabled={loading} fullWidth variant='outlined' error>
            Delete Application
          </NewButton>
        </ConfirmAction>
      </$Buttons>}
      {documentsOpen && <DrawerModal open title={proposalId} handleClose={() => setDocumentsOpen(false)}>
        <ApplicationDocuments
          call={call}
          uid={proposalId}
          handleDownloadClick={handleDownloadClick}
          downloadConsolidated={() => downloadConsolidated(proposalId)}
          documents={documents}
        />
      </DrawerModal>}
    </$ApplicationActions>
  )
}
