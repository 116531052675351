import * as React from 'react'
import { CallFlowContainerProps } from './types'
import { CollapseFlow, Drawer, ELEMENT_TYPES } from 'prace-common-components'
import { $GridCollapseContainer } from './styles'
import { ApplicationActions } from './ApplicationActions'

export const CallFlowContainer: React.FC<CallFlowContainerProps> = ({
  isMobile,
  flow,
  workingStep,
  step,
  form,
  handleSelect,
  open,
  setOpen,
  call,
  handleOpenAssign,
  selectedAssignment,
  assignments,
  onDeleteApplication,
  canAssign,
  canDeleteApplication,
  setLeadAssignment,
  assignmentPartition,
  canMakeLead,
  hasLead,
  handleDownloadClick,
  proposalId,
  documents,
  downloadConsolidated,
  loading,
}) => {

  return (
    isMobile ?
      <Drawer
        maxWidth='300px'
        open={open}
        left
        noHorizPadding
        noTopPadding
        onChange={setOpen}
      >
        <CollapseFlow
          stepper
          flow={flow}
          workingStep={workingStep}
          selectedCollapse={{ type: ELEMENT_TYPES.STEP, order: step}}
          selectedElement={{ type: ELEMENT_TYPES.FORM, order: form}}
          handleSelect={handleSelect}
        />
        <ApplicationActions
          call={call}
          step={step}
          handleSelect={handleSelect}
          handleOpenAssign={handleOpenAssign}
          selectedAssignment={selectedAssignment}
          assignments={assignments}
          onDeleteApplication={onDeleteApplication}
          canAssign={canAssign}
          canDeleteApplication={canDeleteApplication}
          setLeadAssignment={setLeadAssignment}
          assignmentPartition={assignmentPartition}
          canMakeLead={canMakeLead}
          hasLead={hasLead}
          handleDownloadClick={handleDownloadClick}
          proposalId={proposalId}
          documents={documents || []}
          downloadConsolidated={downloadConsolidated}
          loading={loading}
        />
      </Drawer>
      :
      <$GridCollapseContainer item xs={3}>
        <CollapseFlow
          stepper
          flow={flow}
          workingStep={workingStep}
          selectedCollapse={{ type: ELEMENT_TYPES.STEP, order: step}}
          selectedElement={{ type: ELEMENT_TYPES.FORM, order: form}}
          handleSelect={handleSelect}
        />
      </$GridCollapseContainer>
  )
}
